<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "PayForU – Paga Fácil, Cómodo y Seguro",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "PayForU es una novedosa plataforma de pago multimoneda"
      },
      {
        name: "keywords",
        content: "servicio,comercio,finanzas,mall,pago,monedero"
      },
      { name: "author", content: "LikeUGroup" },
      { name: "keywords", content: "malllikeu.@gmail.com" }
    ],
    htmlAttrs: {
      lang: "es",
      amp: true
    }
  }
};
</script>