import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/404.vue')
  },
  {
    path: '/',
    name: 'HomeOne',
    component: () => import(/* webpackChunkName: "home-one" */ '../views/HomeOne.vue')
  },
  {
    path: '/restablecer',
    name: 'Recovery',
    component: () => import(/* webpackChunkName: "recovery" */ '../views/Recovery.vue')
  },
  {
    path: '/contrasena',
    name: 'NewPassword',
    component: () => import(/* webpackChunkName: "contraseña" */ '../views/NewPassword.vue')
  },
  {
    path: '/contacto',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue')
  },
  {
    path: '/activar',
    name: 'ActivateAccount',
    component: () => import(/* webpackChunkName: "activate-account" */ '../views/ActivateAccount.vue')
  },
  {
    path: '/verificar',
    name: 'VerifyUser',
    component: () => import(/* webpackChunkName: "verificar" */ '../views/VerifyUser.vue')
  },
  {
    path: '/stripe',
    name: 'StripePayment',
    component: () => import(/* webpackChunkName: "stripe" */ '../views/StripePayment.vue')
  },
  {
    path: '/pagar',
    name: 'Pay',
    component: () => import(/* webpackChunkName: "pagar" */ '../views/Pay.vue')
  },
  {
    path: '/*',
    name: 'PayMe',
    component: () => import(/* webpackChunkName: "pago" */ '../views/PayMe.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
